const assignmentUser = {
  state: () => ({
    inboxFlag: false,
    pushNotiFlag: false,
    emailFlag: false,
    lineFlag: false,
    selectedRadio: "thai",
    selecteduserList: [],
    assignmentCourse: {},
    assignmentCourseName: null,
    assignmentCourseDescription: "",    
    status: "1",
    startdate: new Date().toISOString().substr(0, 10),
    enddate: new Date().toISOString().substr(0, 10),
    editstartdate: new Date().toISOString().substr(0, 10),
    editenddate: new Date().toISOString().substr(0, 10),
    videostartdate: new Date().toISOString().substr(0, 10),
    videoenddate: new Date().toISOString().substr(0, 10),
    createDate: new Date().toISOString().substr(0, 10),
    pcVideoData: [],
    mushwatchOrder: false,
    prohiForward: false,
    allUserList: false,
    editassignment: false,
    editassignmentcoursedialog: false,
    customSelectedVideo: [],
    editVideoList: [],
    EditVideoTitleList: [],
    assignID: 0,
    inbox: false,
    pushnoti: false,
    email: false,
    //duration:0,
    courseId : null,
    //quizId : null,
    quizType: [], //quiz type can also have up to 2 ids start from R19
    selectedListQuiz :[],
    selectedQuiz : null,
    selectedQuizType: null,
    ListQuizItemsForMap: [],
    addQuizItems:[],
    QuizTypeItems: [],
    courseImgFile: null,
    fileNameOfCourseImg: null,
    selectedCourses: [],
    allEmployees: [],
  }),

  getters: {
    inboxFlag: (state) => state.inboxFlag,
    pushNotiFlag: (state) => state.pushNotiFlag,
    emailFlag: (state) => state.emailFlag,
    selectedRadio: (state) => state.selectedRadio,
    lineFlag: (state) => state.lineFlag,
    customSelectedVideo(state) {
      return state.customSelectedVideo;
    },
    editassignmentcoursedialog(state) {
      return state.editassignmentcoursedialog;
    },
    selecteduserList(state) {
      return state.selecteduserList;
    },
    editassignment(state) {
      return state.editassignment;
    },
    assignmentCourse(state) {
      return state.assignmentCourse;
    },
    assignmentCourseName(state) {
      return state.assignmentCourseName;
    },
    startdate(state) {
      return state.startdate;
    },
    enddate(state) {
      return state.enddate;
    },
    editstartdate(state) {
      return state.editstartdate;
    },
    editenddate(state) {
      return state.editenddate;
    },
    mushwatchOrder(state) {
      return state.mushwatchOrder;
    },
    prohiForward(state) {
      return state.prohiForward;
    },
    allUserList(state) {
      return state.allUserList;
    },
    pcVideoData(state) {
      return state.pcVideoData;
    },
    editVideoList(state) {
      return state.editVideoList;
    },
    EditVideoTitleList(state) {
      return state.EditVideoTitleList;
    },
  },

  mutations: {
    setInboxFlag(state, value) {
      state.inboxFlag = value;
      //localStorage.setItem('inbox_flag', JSON.stringify(value));
    },
    setPushNotiFlag(state, value) {
      state.pushNotiFlag = value;
      //localStorage.setItem('pushNoti_flag', JSON.stringify(value));
    },
    setEmailFlag(state, value) {
      state.emailFlag = value;
      //localStorage.setItem('email_flag', JSON.stringify(value));
    },
    setSelectedRadio(state, value) {
      state.selectedRadio = value; 
      //localStorage.setItem('selected_radio', JSON.stringify(value)); 
    },
    setLineFlag(state, value) {
      state.lineFlag = value;
      //localStorage.setItem('line_flag', JSON.stringify(value));
    },
    openAssignmentEditDialog(state) {
      state.editassignmentcoursedialog = true;
    },

    closeAssignmentEditDialog(state) {
      state.editassignmentcoursedialog = false;
    },
    saveSelecteduserList(state, data) {
      state.selecteduserList = [];
      state.selecteduserList = data;
    },

    removeSelecteduserList(state) {
      state.selecteduserList = [];
    },
    saveAssignmentCourse(state, data) {
      state.assignmentCourse = data;
    },
    changeEditAssignment(state, data) {
      state.editassignment = data;
    },

    saveTwiceEditPcAndVideo(state, data) {
      state.pcVideoData = data;
    },
    removePcAndVideo(state) {
      state.pcVideoData = [];
    },
    changeState() {},

    addCustomSelectedVideo(state, data) {
      state.customSelectedVideo = data;
    },
    removeCustomSelectedVideo(state) {
      state.customSelectedVideo = [];
    },

    saveEditVideoTitleList(state, data) {
      state.EditVideoTitleList = data;
    },
    removeEditVideoTitleList(state) {
      state.EditVideoTitleList = [];
    },

    saveeditVideoList(state, data) {
      if (data != null) {
        if (state.editassignment == true) {
          if (state.editVideoList.length > 0) {
            for (const element of data) {
              if (element.partfileList != undefined) {
                for (let j of element.partfileList) {
                  let result = state.editVideoList.find(
                    (x) => x.oldVideoID == j.id
                  );
                  if (result == undefined) {
                    state.editVideoList.push({
                      id: 0,
                      assignmentCourseID: 0,
                      oldID: 0,
                      oldVideoID: j.id,
                      startContentDate: j.createDate,
                      endContentDate: j.updateDate,
                      videoName: j.songName,
                      title: element.lessonName,
                      description: element.wordDescription,
                      size: j.size,
                      popupTime: 0,
                      duration: Duration(j.time),
                      videoFile: j.videoFile,
                      allowDownload: element.allowDownload,
                      pictureBackground: element.picturebackground,
                      thumbnail: element.thumbnail,
                      permissionData: [],
                      no: parseInt(state.editVideoList.length + 1),
                      part: parseInt(state.editVideoList.length) + 1,//"Part" +
                      pictureFile: "",
                    });
                  }
                }
              }
            }
          } else {
            if (data[0].lessonName != undefined) {
              for (const element of data) {
                for (let j of element.partfileList) {
                  state.editVideoList.push({
                    id: 0,
                    assignmentCourseID: 0,
                    oldID: 0,
                    oldVideoID: j.id,
                    startContentDate: j.createDate,
                    endContentDate: j.updateDate,
                    videoName: j.songName,
                    title: element.lessonName,
                    description: element.wordDescription,
                    size: j.size,
                    popupTime: 0,
                    duration: Duration(j.time),
                    videoFile: j.videoFile,
                    allowDownload: element.allowDownload,
                    pictureBackground: element.picturebackground,
                    thumbnail: element.thumbnail,
                    permissionData: [],
                    no: parseInt(state.editVideoList.length + 1),
                    part: parseInt(state.editVideoList.length + 1), // "Part" +
                    pictureFile: "",
                  });
                }
              }
            } else {
              state.editVideoList = data.map((v, i) => ({
                ...v,
                no: i + 1,               
              }));
            }
          }
        } else {
          for (const element of data) {
            for (let j of element.partfileList) {
              state.editVideoList.push({
                id: 0,
                assignmentCourseID: 0,
                oldID: 0,
                oldVideoID: j.id,
                startContentDate: j.createDate,
                endContentDate: j.updateDate,
                videoName: j.songName,
                title: element.lessonName,
                description: element.wordDescription,
                size: j.size,
                popupTime: 0,
                duration: Duration(j.time),
                videoFile: j.videoFile,
                allowDownload: element.allowDownload,
                pictureBackground: element.picturebackground,
                thumbnail: element.thumbnail,
                permissionData: [],
                no: parseInt(state.editVideoList.length + 1),
                part: parseInt(state.editVideoList.length + 1), //"Part" +
                pictureFile: "",
              });
            }
          }
        }
      } else {
        state.editVideoList = [];
      }

    },
  },
};
function Duration(duration) {
  let time = [Math.floor(duration / 60) + "", Math.floor(duration % 60) + ""];
  time.forEach((value) => {
    while (value.length < 2) value = "0" + value;
  });
  return `${time[0]}:${time[1]}`;
}
export default assignmentUser;
