<template>
  <!-- New breadcrumb -->
  <div class="mb-2 mt-3" style>
    <v-toolbar class="breadcrumb-style ma-3">
      <v-toolbar-title v-if="!$vuetify.breakpoint.xs">
        <span class="text-wrap-style" style="font-size: 32px; color: #424242; font-weight: 400;padding-right: 5rem;">{{
          $t(page_title)
        }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex text-wrap-style">
        <span style="font-size: 16px; color: #424242">{{
          $t(items[0].sidebar_tilte)
        }}</span>
      </div>
      <div v-for="(item, i) in items" :key="i" @click="$router.push(item.href)" class="d-flex align-center" >
        <span
          style="font-size: 16px"
          class="ml-1 mr-1 text-wrap-style"
          :style="[
            item.href ? { cursor: 'pointer' } : {},
            items[items.length - 1].text == item.text
              ? { color: '#A6CC39' }
              : { color: '#424242' },
          ]"
          >{{ $t(item.text) }}</span
        >
        <!-- item.text == page_title || -->
        <span v-if="items.length - 1 != i" style="color: #424242">/</span>
      </div>

      <!-- for mobile size -->
      <div
        v-if="!$vuetify.breakpoint.xs"
        class="d-flex align-center"
        style="height: 48px"
      >
        <!-- optional some page does not include -->
        <v-divider
          v-if="item_no"
          class="ml-2"
          vertical
          inset
          style="
            border-color: #424242;
            opacity: 0.2;
            max-height: calc(100% - 12px);
          "
        ></v-divider>

        <div class="d-flex ml-2 mr-2" v-if="item_no">
          <span style="font-size: 16px; color: #a6cc39"
            >{{ item_no }} {{ $t("Items") }}</span
          >
        </div>
        <!-- Library icons... -->
        <v-divider
          v-if="item_icon"
          vertical
          inset
          style="
            border-color: #424242;
            opacity: 0.2;
            max-height: calc(100% - 12px);
          "
        ></v-divider>
        <div class="d-flex mt-1 ml-3 mr-3" v-if="item_icon">
          <v-icon
            style="border: 1px solid #c4c4c4"
            @click="SortCategory('bullet')"
            >mdi-format-list-bulleted</v-icon
          >
          <v-icon
            class="ml-2"
            style="border: 1px solid #c4c4c4"
            @click="SortCategory('grid')"
            >mdi-view-grid</v-icon
          >
        </div>
        <!-- Add/Edit smth button -->
        <v-divider
          v-if="item_btn_add || item_btn_edit"
          vertical
          inset
          class="ml-1"
          style="
            border-color: #424242;
            opacity: 0.2;
            max-height: calc(100% - 12px);
          "
        ></v-divider>
        <div class="d-flex pl-3" v-if="item_btn_add">
          <v-btn
            dark
            class="add_btn text-capitalize white--text"
            color="#A6CC39"
            style="border-radius: 8px !important"
            @click="Actions('Add')"
          >
            <v-icon style="color: #ffffff" class="mr-1" :small="true"
              >mdi-plus-box-outline</v-icon
            >
            <span style="font-size: 14px; color: #ffffff">{{
              $t(btn_text)
            }}</span>
          </v-btn>
        </div>

        <div class="d-flex pl-3" v-if="item_btn_edit">
          <v-btn
            dark
            class="text-capitalize white--text add_btn"
            color="#A6CC39"
            style="border-radius: 8px !important"
            @click="Actions('Edit')"
          >
            <v-icon style="color: #ffffff" class="mr-1" :small="true"
              >mdi-square-edit-outline</v-icon
            >
            <span style="font-size: 14px; color: #ffffff">{{
              $t(btn_text)
            }}</span>
          </v-btn>
        </div>

        <!-- Export Excel Action Button -->
        <v-divider
          v-if="excel_btn"
          class="ml-3 mr-3"
          vertical
          inset
          style="
            border-color: #424242;
            opacity: 0.2;
            max-height: calc(100% - 10px);
          "
        ></v-divider>
        <div class="d-flex pl-3" v-if="excel_btn">
          <v-btn
            @click="Actions('Export')"
            outlined
            depressed
            color="#A6CC39"
            style="
              font-size: 16px;
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              height: 40px;
            "
            class="text-capitalize rounded-lg mr-0"
          >
            <v-icon>mdi-file-upload-outline</v-icon>
            {{ $t("exportexcel") }}
          </v-btn>
        </div>
      </div>

      <template
        v-if="
          item_no || item_icon || item_btn_add || item_btn_edit || excel_btn
            ? $vuetify.breakpoint.xs
            : false
        "
        v-slot:extension
      >
        <v-toolbar-items>
          <div
            class="d-flex justify-center align-center ml-4 mr-4"
            v-if="item_no"
          >
            <span style="font-size: 16px; color: #a6cc39"
              >{{ item_no }} {{ $t("Items") }}</span
            >
          </div>
          <!-- Library icons... -->
          <v-divider
            v-if="item_icon"
            vertical
            inset
            style="
              border-color: #424242;
              opacity: 0.2;
              max-height: calc(100% - 15px);
            "
          ></v-divider>
          <div
            class="d-flex justify-center align-center ml-3 mr-3"
            v-if="item_icon"
          >
            <v-icon
              style="border: 1px solid #c4c4c4"
              @click="SortCategory('bullet')"
              >mdi-format-list-bulleted</v-icon
            >
            <v-icon
              class="ml-2"
              style="border: 1px solid #c4c4c4"
              @click="SortCategory('grid')"
              >mdi-view-grid</v-icon
            >
          </div>
          <!-- Add/Edit smth button -->
          <v-divider
            v-if="item_btn_add || item_btn_edit"
            vertical
            inset
            class="ml-1"
            style="
              border-color: #424242;
              opacity: 0.2;
              max-height: calc(100% - 15px);
            "
          ></v-divider>
          <div class="d-flex align-center pl-3" v-if="item_btn_add">
            <v-btn
              dark
              class="add_btn text-capitalize white--text"
              color="#A6CC39"
              style="border-radius: 8px !important"
              @click="Actions('Add')"
            >
              <v-icon style="color: #ffffff" class="mr-1" :small="true"
                >mdi-plus-box-outline</v-icon
              >
              <span style="font-size: 14px; color: #ffffff">{{
                $t(btn_text)
              }}</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pl-3" v-if="item_btn_edit">
            <v-btn
              dark
              class="text-capitalize white--text add_btn"
              color="#A6CC39"
              style="border-radius: 8px !important"
              @click="Actions('Edit')"
            >
              <v-icon style="color: #ffffff" class="mr-1" :small="true"
                >mdi-square-edit-outline</v-icon
              >
              <span style="font-size: 14px; color: #ffffff">{{
                $t(btn_text)
              }}</span>
            </v-btn>
          </div>

          <!-- Export Excel Action Button -->
          <v-divider
            v-if="excel_btn"
            class="ml-3 mr-3"
            vertical
            inset
            style="
              border-color: #424242;
              opacity: 0.2;
              max-height: calc(100% - 20px);
            "
          ></v-divider>
          <div class="d-flex align-center pl-3" v-if="excel_btn">
            <v-btn
              @click="Actions('Export')"
              outlined
              depressed
              color="#A6CC39"
              style="
                font-size: 16px;
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                height: 40px;
              "
              class="text-capitalize rounded-lg mr-0"
            >
              <v-icon>mdi-file-upload-outline</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
          </div>
        </v-toolbar-items>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    page_title: String,
    item_no: Number,
    item_icon: Boolean,
    item_btn_add: Boolean,
    item_btn_edit: Boolean,
    btn_text: String,
    excel_btn: Boolean,
    //.............................
    item_sub: Boolean,
    //faq_btn: Boolean
  },
  data() {
    return {
      companyName: "",
    };
  },
  mounted() {
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.companyName = auth.companyName;
    }
  },
  methods: {
    Actions(v) {
      this.$emit("ShowAction", v);
    },
    SortCategory(x) {
      this.$emit("sort_categories", x);
    },
  },
};
</script>

<style scoped>
.breadcrumb-style {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 16px;
  opacity: 1;
}
.text-wrap-style {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;  
  cursor: pointer;
  white-space: pre-line;
}
::v-deep .v-toolbar__extension {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: end;
  z-index: 0;
  padding-bottom: 10px;
}
</style>

<style>
.add_btn {
  color: #fff;
  width: 100%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.add_btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.add_btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.add_btn:hover {
  transition: 0.25s;
}
.add_btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
